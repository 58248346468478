import { Grid, GridItem, Text, Toggle } from '@/components'
import {
  AlertMessage,
  BehindFlag,
  ConnectionAlertMessage,
  NavigationPage,
} from '@/app/components'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import { AdaptiveCardRowLayout } from '@/app/layouts'
import Lottie from 'lottie-react'
import EstimatedAnnualSavingsRow from 'features/devices/thermostat/EstimatedAnnualSavingsRow'
import { thermostatSettingsCollection } from '@/reducers/thermostatSettings'
import { useParams } from 'react-router-dom'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import {
  Thermostat,
  ThermostatComfortSetting,
  ThermostatSettings,
} from '@/types'
import { useAppDispatch } from '@/hooks'
import { debounce } from '@/utils/limit'
import { useCallback, useState } from 'react'
import { thermostatComfortSettingsCollection } from '@/reducers/thermostatComfortSettings'
import { thermostatSavingsSummariesCollection } from '@/reducers/thermostatSavingsSummaries'
import { useBrandedThermostatName } from '@/hooks/useBrandedThermostatName'
import { thermostatsCollection } from '@/reducers/thermostats'
import EventSettingsRow from '@/app/features/devices/thermostat/EventSettingsRow'

type ViewModelProps = {
  settings: ThermostatSettings | undefined
  settingsError: boolean
  comfortSettings: ThermostatComfortSetting[]
  comfortSettingsError: boolean
  thermostatId: Thermostat['id']
}

function useViewModel(props: ViewModelProps) {
  const dispatch = useAppDispatch()

  const {
    data: savingsSummary,
    status: savingsSummaryStatus,
    error: savingsError,
    refetch: refetchSavingsSummary,
  } = thermostatSavingsSummariesCollection.useFetch(
    `/thermostats/${props.thermostatId}/savings_summary/`,
  )

  const [localGridSupportEnabled, setLocalGridSupportEnabled] = useState(
    props.settings?.grid_support_enabled,
  )
  // debounce settings update
  const debouncedUpdateSettings = useCallback(
    debounce((updatedSetttings: Partial<ThermostatSettings>) => {
      dispatch(
        thermostatSettingsCollection.actions.update(
          `/thermostats/${props.thermostatId}/settings/`,
          updatedSetttings,
          { id: props.settings?.id },
        ),
      )
    }, 500),
    [props.thermostatId, props.settings?.id],
  )

  const brandedThermostatName = useBrandedThermostatName(props.thermostatId)

  const onGridSupportToggle = (checked: boolean) => {
    setLocalGridSupportEnabled(checked)
    debouncedUpdateSettings({ grid_support_enabled: checked })
  }

  const onComfortSettingsChange = () => {
    refetchSavingsSummary()
  }

  const savingsCents = savingsSummary[0]?.grid_support_savings_cents

  return {
    brandedThermostatName,
    localGridSupportEnabled,
    onGridSupportToggle,
    onComfortSettingsChange,
    thermostatId: props.thermostatId,
    comfortSettings: props.comfortSettings,
    savingsCents: savingsCents,
    savingsLoading: savingsSummaryStatus === 'loading',
    savingsError,
    settingsError: props.settingsError,
    comfortSettingsError: props.comfortSettingsError,
  }
}

function ThermostatGridSupportPageComponent(
  props: ReturnType<typeof useViewModel>,
) {
  if (props.settingsError) {
    return (
      <NavigationPage
        id="thermostat-grid-support-page"
        title="Support Your Local Grid"
        subtitle={props.brandedThermostatName ?? 'Thermostat'}
        navigationOptions={['back']}
      >
        <AlertMessage variant="error" className="mt-[32px]">
          There was an error with your settings. Please try again later.
        </AlertMessage>
      </NavigationPage>
    )
  }

  return (
    <NavigationPage
      id="thermostat-grid-support-page"
      title="Support Your Local Grid"
      subtitle={props.brandedThermostatName ?? 'Thermostat'}
      navigationOptions={['back']}
    >
      <AdaptiveCardRowLayout.PlainRow className="mt-0">
        <GridItem placeItems="center">
          <Lottie
            className="w-1/2"
            animationData={connectUtilityAnimation}
            loop
          />
        </GridItem>
      </AdaptiveCardRowLayout.PlainRow>
      <ConnectionAlertMessage connectionRequirements={['meter']} />
      <AdaptiveCardRowLayout.CardRow>
        <Grid flow="column">
          <GridItem>
            <Text variant="subheader">Support Your Local Grid</Text>
            <Text variant="body2">
              Widen your acceptable temperature range occasionally when grid
              stress is high
            </Text>
          </GridItem>
          <GridItem className="pl-16 pt-2">
            <Toggle
              id="thermostat-grid-support-toggle"
              checked={props.localGridSupportEnabled}
              onChange={props.onGridSupportToggle}
            />
          </GridItem>
        </Grid>
      </AdaptiveCardRowLayout.CardRow>
      <EventSettingsRow
        editingEnabled={Boolean(props.localGridSupportEnabled)}
        comfortSettingsError={props.comfortSettingsError}
        thermostatId={props.thermostatId}
        comfortSettings={props.comfortSettings}
        onComfortSettingsChange={props.onComfortSettingsChange}
      />
      <AdaptiveCardRowLayout.CardRow bottomRule={false}>
        <EstimatedAnnualSavingsRow
          error={Boolean(props.savingsError)}
          loading={props.savingsLoading}
          savingsCents={props.localGridSupportEnabled ? props.savingsCents : 0}
        />
      </AdaptiveCardRowLayout.CardRow>
    </NavigationPage>
  )
}

function ThermostatGridSupportPage(props: ViewModelProps) {
  const viewModel = useViewModel(props)
  return <ThermostatGridSupportPageComponent {...viewModel} />
}

function ThermostatGridSupportPageWithFallback(props: {
  thermostatId: Thermostat['id']
}) {
  const {
    data: thermostats,
    status: thermostatStatus,
    error: thermostatError,
  } = thermostatsCollection.useFetch(props.thermostatId)

  const {
    data: settingsArray,
    status: settingsStatus,
    error: settingsError,
  } = thermostatSettingsCollection.useFetch(
    `/thermostats/${props.thermostatId}/settings/`,
  )
  const {
    data: comfortSettings,
    status: comfortSettingsStatus,
    error: comfortSettingsError,
  } = thermostatComfortSettingsCollection.useFetch(
    `/thermostats/${props.thermostatId}/comfort_settings/`,
  )

  const thermostat = thermostats[0] as Thermostat | undefined
  const thermostatLoading =
    thermostatStatus !== 'succeeded' && !thermostatError && !thermostat
  const settings = settingsArray[0] as ThermostatSettings | undefined
  const settingsLoading =
    !settingsError && settingsStatus !== 'succeeded' && !settings
  const comfortSettingsLoading =
    !comfortSettingsError &&
    comfortSettingsStatus !== 'succeeded' &&
    comfortSettings.length === 0
  const loading = settingsLoading || comfortSettingsLoading || thermostatLoading

  if (loading) {
    return <PageLoader />
  }

  return (
    <ThermostatGridSupportPage
      settings={settings}
      settingsError={Boolean(settingsError)}
      comfortSettings={comfortSettings}
      comfortSettingsError={Boolean(comfortSettingsError)}
      thermostatId={props.thermostatId}
    />
  )
}

type PathParams = { deviceId: string | undefined }

export default function ThermostatGridSupportPageRoot() {
  // todo - page should be loading until thermostat with id
  const { deviceId } = useParams<PathParams>()
  if (!deviceId) return <PageLoader />
  const thermostatId = parseInt(deviceId)
  if (isNaN(thermostatId)) return <PageLoader />
  return (
    <BehindFlag flag="thermostat_schedule_settings" experimental>
      <ThermostatGridSupportPageWithFallback thermostatId={thermostatId} />
    </BehindFlag>
  )
}
